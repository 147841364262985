/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuthenticationService as authService } from 'admin-portal-shared-services';
import axios, { AxiosInstance } from 'axios';
import { Vendor } from 'interfaces/Vendors';
import { v4 } from 'uuid';
import { getToken } from '../../helpers';
import { getCompanyBFFHost } from '../host/HostService';
import type { PatchVendorImageParams, RetrieveVendorByIdParams } from './types';

export class VendorService {
  private defaultHeaders = {};

  public axiosInstance: AxiosInstance | any;

  constructor() {
    this.axiosInstance = axios.create();
    this.defaultHeaders = {
      Authorization: getToken(),
      requestTraceId: v4(),
    };
  }

  async patchVendorImage({ vendorId, country, file }: PatchVendorImageParams): Promise<void> {
    if (!file) {
      throw new Error('invalid File');
    }

    const dataForm = new FormData();
    dataForm.append('file', file);
    dataForm.append('name', `${vendorId}-${country}`);

    await authService()
      .enhancedAxios(this.axiosInstance)
      .patch(`${getCompanyBFFHost()}/v1/vendors/${vendorId}/thumbnail`, dataForm, {
        headers: {
          ...this.defaultHeaders,
          country,
          vendorId,
          'content-type': 'multipart/form-data',
        },
      });
  }

  async getVendorById({ vendorId, country }: RetrieveVendorByIdParams): Promise<Vendor> {
    const { data } = await authService()
      .enhancedAxios(this.axiosInstance)
      .get(`${getCompanyBFFHost()}/v1/vendors/${country}/${vendorId}`, {
        headers: {
          ...this.defaultHeaders,
          country,
        },
      });

    return data;
  }
}
