
import { X } from '@admin-portal-shared-components/icons';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { v4 as uuid } from 'uuid';
import * as styled from './styles';

interface IChips {
  chips: string[];
  setChips: Dispatch<SetStateAction<string[]>>;
  disabled?: boolean;
}

export const Chips: FC<IChips> = ({ chips, setChips, disabled = false }) => {
  const handleClickRemoveChip = (event: React.BaseSyntheticEvent) => {
    const chipId = event.target.getAttribute('id');

    setChips(chips.filter((chip) => chip !== chipId));
  };

  return (
    <>
      {chips.length > 0 &&
        chips.map((chip) => {
          return (
            <styled.ChipsContainer key={uuid()} test-dataid="chip">
              {chip}
              <button
                id={chip}
                onClick={handleClickRemoveChip}
                type="button"
                tabIndex={0}
                data-testid={`close-button-${chip}`}
                disabled={disabled}
              >
                <X size="tiny" />
              </button>
            </styled.ChipsContainer>
          );
        })}
    </>
  );
};