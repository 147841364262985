import { InfoOutlined } from '@material-ui/icons';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { StyledFeedbacksContainer, StyledMessageContainer, StyledSnackbar } from './Styles';

export interface IFeedback {
  message: string;
  variant: 'success' | 'error';
  lifeTime?: number;
  elementRef: React.MutableRefObject<undefined>;
}
export interface IFeedbacksFunctions {
  pushNewFeedback: (feedback: IFeedback) => void;
}

export const pushNewFeedback = (feedback: IFeedback) => {
  (feedback?.elementRef?.current as IFeedbacksFunctions | undefined)?.pushNewFeedback(feedback);
};

export const Feedbacks = forwardRef(function Feedback(_props, ref): JSX.Element {
  const [feedbacks, setFeedbacks] = useState<IFeedback[]>([]);

  const pushFeedback = (newFeedback: IFeedback) => {
    setFeedbacks([...feedbacks, newFeedback]);
  };

  const removeFeedback = (feedback: IFeedback) => {
    setFeedbacks(feedbacks.filter((fb) => fb !== feedback));
  };

  useImperativeHandle(ref, () => ({
    pushNewFeedback: (newFeedback: IFeedback) => pushFeedback(newFeedback),
  }));

  return (
    <StyledFeedbacksContainer data-testid="feedbacks">
      {feedbacks.map((feedback: IFeedback) => (
        <StyledSnackbar
          key={feedbacks.indexOf(feedback)}
          open={feedbacks.includes(feedback)}
          autoHideDuration={feedback.lifeTime || 3000}
          onClose={() => removeFeedback(feedback)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          variant={feedback.variant}
          data-testid="feedback"
        >
          <StyledMessageContainer>
            <InfoOutlined />
            {feedback.message}
          </StyledMessageContainer>
        </StyledSnackbar>
      ))}
    </StyledFeedbacksContainer>
  );
});
