import styled from 'styled-components';

export const ChipsContainer = styled.div`
  align-items: center;
  border-radius: 16px;
  background-color: #e9f1ff;
  color: #000000;
  display: inline-flex;
  font: 500 14px/16px 'Work Sans', sans-serif;
  height: 32px;
  padding: 8px 16px;

  & button {
    border: none;
    background-color: transparent;
    color: #0363c4;
    margin-left: 8px;
    width: 12px;
    height: 12px;
    line-height: 12px;
    cursor: pointer;

    & svg {
      pointer-events: none;
    }

    &:hover {
      opacity: 0.8;
    }
  }
`;