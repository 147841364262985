import { IVendor } from 'interfaces/Vendors';
import React, { useEffect, useState } from 'react';
import { OrderNotificationService } from 'services/orderNotification/OrderNotificationService';
import { VendorsFilter } from './Filters/VendorsFilter';
import * as Styled from './styles';

interface Props {
  vendorChange: (vendor: IVendor) => void;
}

export function Filter({ vendorChange }: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [vendors, setVendors] = useState<IVendor[]>([]);

  const orderNotificationService = new OrderNotificationService();
  const getVendors = async () => {
    setIsLoading(true);
    const vendors = await orderNotificationService.getVendors();
    setVendors(vendors);
    setIsLoading(false);
  };

  useEffect(() => {
    getVendors();
  }, []);

  return (
    <Styled.StyledFilterContainer>
      <Styled.StyledFilterContainerHeader>
        <Styled.StyledFilterHeaderTitle>Filter by</Styled.StyledFilterHeaderTitle>
      </Styled.StyledFilterContainerHeader>
      <Styled.StyledFiltersContainer>
        <VendorsFilter vendors={vendors} onHandleChange={vendorChange} isLoading={isLoading} />
      </Styled.StyledFiltersContainer>
    </Styled.StyledFilterContainer>
  );
}
