import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import { AlertProps } from '@material-ui/lab';
import React from 'react';
import { StyledAlert } from './styles';

const icons: any = {
  success: <InfoOutlinedIcon htmlColor="#007A78" />,
  info: <InfoOutlinedIcon htmlColor="#007A78" />,
  error: <ReportOutlinedIcon htmlColor="#CA201D" />,
};

export const Alert = ({ children, severity, ...otherProps }: AlertProps): JSX.Element => {
  return (
    <StyledAlert {...otherProps} severity={severity} icon={icons[severity || 'success']}>
      {children}
    </StyledAlert>
  );
};
