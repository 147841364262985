import React, { Dispatch, FC, KeyboardEvent, SetStateAction } from 'react';
import * as styled from './styles';

interface IChipField {
  value: string;
  onChange: (event: React.BaseSyntheticEvent) => void;
  chips: string[];
  setChips: Dispatch<SetStateAction<string[]>>;
  setValue: Dispatch<SetStateAction<string>>;
  disabled?: boolean;
}

enum EnumKeyBoard {
  SPACE = ' ',
  TAB = 'Tab',
  ENTER = 'Enter',
  COMMA = ',',
}

export const InputChip: FC<IChipField> = ({ onChange, value, setValue, chips, setChips, disabled = false }) => {

  const validateChipValues = (value: string) => {
    const formatterIdValues = value.split(/[,;\n\r\s]/g);
    const idValues = formatterIdValues.filter((id) => id !== '');

    return idValues;
  };

  const removeDuplicates = (value: string, index: number, self: string[]) => {
    return self.indexOf(value) === index
  };

  const addChipValues = () => {
    const chipValues = validateChipValues(value);

    setChips([...chips, ...chipValues].filter(removeDuplicates));
    setValue('');
  };

  const handleKeyDownAddChip = (event: KeyboardEvent) => {
    const SPACE_KEY = event.key === EnumKeyBoard.SPACE;
    const TAB_KEY = event.key === EnumKeyBoard.TAB;
    const ENTER_KEY = event.key === EnumKeyBoard.ENTER;
    const COMMA_KEY = event.key === EnumKeyBoard.COMMA;

    if ((SPACE_KEY || TAB_KEY || ENTER_KEY || COMMA_KEY) && value !== '') {
      event.preventDefault();
      addChipValues();
    }
  };

  const handleBlurAddChip = () => value !== '' && addChipValues();

  return (
    <styled.InputChip
      id="chips"
      type="text"
      value={value}
      onChange={onChange}
      onFocus={handleBlurAddChip}
      onKeyDown={handleKeyDownAddChip}
      tabIndex={0}
      autoComplete="off"
      data-testid="input-add-chip"
      disabled={disabled}
    />
  );
};