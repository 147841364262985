import styled, { css, keyframes } from 'styled-components';

interface IButton {
  variant?: string;
}

const modalAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const ModalBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
`;

export const ModalContainer = styled.div`
  box-sizing: border-box;
  width: 534px;
  height: auto;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  padding: 32px;
  animation: ${modalAnimation} 400ms ease-in-out;
  animation-fill-mode: forwards;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalTitle = styled.h2`
  font: 600 32px/40px 'Barlow', sans-serif;
  color: rgba(0, 0, 0, 0.9);
  margin: 0;
`;

export const ModalSubtitle = styled.p`
  font: 400 16px/24px 'Work Sans', sans-serif;
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const ModalFooter = styled.div`
  display: flex;
  place-content: flex-end;
  cursor: pointer;
`;

export const ModalButtonClose = styled.button`
  width: 32px;
  height: 32px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.15);
  cursor: pointer;

  & svg {
    color: rgba(0, 0, 0, 0.9);
    pointer-events: none;
  }
`;

export const ModalButton = styled.button<IButton>`
  height: 48px;
  border: 0;
  border-radius: 24px;
  display: flex;
  place-items: center;
  font: 500 18px/24px 'Work Sans', sans-serif;
  color: #fff;
  background-color: #000000;
  padding: 0 24px;
  cursor: pointer;
  margin-right: 16px;
  text-decoration: none;

  &[disabled] {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    color: rgba(0, 0, 0, 0.55);
    cursor: not-allowed;
  }

  ${({ variant }) =>
    variant === 'outline' &&
    css`
      color: #000;
      border: 1px solid #000000;
      border-radius: 24px;
      background-color: #ffffff;
    `}
`;
