import React from 'react';
import { ITabs } from '.';
import { Tab, TabContainer, TabList, TabPanel } from './styles';

export const Tabs = ({ tabActive, tabItems, tabPanels }: ITabs): JSX.Element => {
  const tabListWithAccess = tabItems.filter((tabItem) => tabItem.hasPermission === true);

  return (
    <TabContainer defaultValue={tabActive}>
      <TabList>
        {tabListWithAccess?.map((tabItem) => (
          <Tab
            key={tabItem.value}
            value={tabItem.value}
            disabled={tabItem.disabled}
            onClick={tabItem.onCallBack}
          >
            {tabItem.label}
          </Tab>
        ))}
      </TabList>
      {tabPanels?.map((tabPanel) => (
        <TabPanel key={tabPanel.value} value={tabPanel.value}>
          {tabPanel.content}
        </TabPanel>
      ))}
    </TabContainer>
  );
};
