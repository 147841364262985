/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup';
import { Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { useHasPermission } from 'admin-portal-shared-services';
import { Button } from 'components/Button';
import { Feedbacks, pushNewFeedback } from 'components/Feedbacks/Feedbacks';
import { Vendor } from 'interfaces/Vendors';
import { useOrderNotification } from 'pages/OrderNotification/provider/orderNotificationContext';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { VendorService } from 'services/vendor';
import * as Yup from 'yup';
import { CompanyImageSkeleton } from './CompanyImageSkeleton';
import {
  ButtonsContainer,
  Container,
  Form,
  Heading,
  HeadingContainer,
  Image,
  InputGroup,
  Label,
  LabelContainer,
  Preview,
  Shadow,
  StyledInput,
  TooltipContainer,
} from './styles';
import { CompanyImageProps } from './types';

const validationSchema = Yup.object().shape({
  file: Yup.mixed().required('The image is required'),
});

export const CompanyImage = ({ country, vendorId }: CompanyImageProps): JSX.Element => {
  const feedbackRef = React.useRef();
  const [preview, setPreview] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const [vendor, setVendor] = useState<Vendor>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingVendor, setIsLoadingVendor] = useState<boolean>(false);
  const { hasChange, setHasChange } = useOrderNotification();

  const hasPermissionToAccessImageConfiguration = useHasPermission('CompanySettings.Image.Write');

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const vendorService = new VendorService();

  const isDisabled = () => isLoading || !hasChange || !hasPermissionToAccessImageConfiguration;

  const generatePreview = (
    event: React.FormEvent<HTMLInputElement>,
    onChange: (e: any) => void
  ) => {
    const target = event.target as HTMLInputElement;
    const files = target?.files as FileList;
    const FILE_INDEX = 0;
    const file = files[FILE_INDEX];
    const previewURL = URL.createObjectURL(file);

    onChange(file);
    setFileName(file.name);
    setPreview(previewURL);
    setHasChange(true);
  };

  const setPreviewByVendor = (vendorData: Vendor | undefined): void => {
    if (!vendorData || !vendorData.thumbnailUrl) {
      setPreview('');
      setFileName('');
    } else {
      setPreview(vendorData.thumbnailUrl);
      getVendorThumbnailFileName(vendorData);
    }
  };

  const getVendorThumbnailFileName = (vendorData: Vendor): void => {
    const FILE_NAME_INDEX = 1;
    const MEDIA = 'media/';
    const thumbnailData = vendorData.thumbnailUrl?.split(MEDIA);
    if (thumbnailData && thumbnailData.length > 1) {
      const thumbnailFileName = thumbnailData[FILE_NAME_INDEX];
      setFileName(thumbnailFileName);
    }
  };

  const openSelectFileWindow = () => {
    const current = inputRef.current as HTMLInputElement;
    current.click();
  };

  const clearForm = () => {
    reset();
    setPreviewByVendor(vendor);
    setHasChange(false);
  };

  const onSubmit = async ({ file }: any) => {
    try {
      setIsLoading(true);
      await vendorService.patchVendorImage({
        vendorId,
        country,
        file,
      });

      pushNewFeedback({
        message: 'Image Configuration successfully updated.',
        variant: 'success',
        elementRef: feedbackRef,
      });
    } catch (error) {
      pushNewFeedback({
        message: `Image Configuration couldn't be updated now.`,
        variant: 'error',
        elementRef: feedbackRef,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setHasChange(false);
  }, []);

  useEffect(() => {
    const retrieveVendor = async () => {
      setFileName('');
      setPreview('');
      setIsLoadingVendor(true);
      try {
        const vendorData = await vendorService.getVendorById({ vendorId, country });
        setPreviewByVendor(vendorData);
        setVendor(vendorData);
      } catch (error) {
        pushNewFeedback({
          message: `Cannot retrieve the vendor`,
          variant: 'error',
          elementRef: feedbackRef,
        });
      } finally {
        setIsLoadingVendor(false);
      }
    };

    retrieveVendor();
  }, [vendorId]);

  if (isLoadingVendor) {
    return <CompanyImageSkeleton />;
  }

  return (
    <Container>
      <HeadingContainer>
        <Heading>Insert the company image</Heading>
        <TooltipContainer>
          <Tooltip
            title="Your image must have 300x300 pixels and be in the png format"
            placement="right"
            style={{ marginLeft: '10px' }}
          >
            <InfoOutlined />
          </Tooltip>
        </TooltipContainer>
      </HeadingContainer>
      <Form role="form" onSubmit={handleSubmit(onSubmit)}>
        <InputGroup>
          <Controller
            control={control}
            name="file"
            render={({ field: { onChange } }) => (
              <StyledInput
                ref={inputRef}
                type="file"
                id="fileUpload"
                data-testid="upload-image"
                accept="image/png"
                value=""
                onChange={(e) => generatePreview(e, onChange)}
              />
            )}
          />
          <LabelContainer htmlFor="fileUpload">
            <Button type="button" onClick={() => openSelectFileWindow()}>
              Choose file
            </Button>
            <Label>{fileName}</Label>
          </LabelContainer>
          <Shadow>
            <Preview>
              <Image src={preview} />
            </Preview>
          </Shadow>
        </InputGroup>
        <ButtonsContainer>
          <Button
            type="button"
            variant="secondary"
            size="large"
            data-testid="cancel-button"
            css={{
              marginRight: '24px',
            }}
            disabled={isDisabled()}
            onClick={clearForm}
          >
            Cancel
          </Button>
          <Button type="submit" size="large" disabled={isDisabled()}>
            Save Changes
          </Button>
        </ButtonsContainer>
      </Form>
      <Feedbacks ref={feedbackRef} />
    </Container>
  );
};
