import { X } from '@admin-portal-shared-components/icons';
import React, { FC } from 'react';
import * as Styled from './styles';

export interface IModal {
  modalTitle: string;
  isModalOpen: boolean;
  handleButtonCloseModal: () => void;
}

export const Modal: FC<IModal> = ({
  modalTitle,
  isModalOpen,
  handleButtonCloseModal,
  children,
}) => {
  const handleBackgroundClose = (event: React.MouseEvent<HTMLDivElement>) => {
    const isSafeArea = (event.target as Element).closest('[data-safe-area="true"]');

    if (!isSafeArea) {
      handleButtonCloseModal();
    }
  };

  return (
    <>
      {isModalOpen && (
        <Styled.ModalBackground onClick={handleBackgroundClose} data-testid="modal-background">
          <Styled.ModalContainer data-safe-area="true">
            <Styled.ModalHeader>
              <Styled.ModalTitle>{modalTitle}</Styled.ModalTitle>
              <Styled.ModalButtonClose onClick={handleButtonCloseModal} data-testid="close-modal">
                <X size="medium" />
              </Styled.ModalButtonClose>
            </Styled.ModalHeader>
            {children}
          </Styled.ModalContainer>
        </Styled.ModalBackground>
      )}
    </>
  );
};
