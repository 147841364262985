import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import React from 'react';
import { GlobalStyle } from './global/global';
import { IntlProvider } from './i18n';
import { AppBarConfigProvider } from './providers/AppConfigProvider/AppConfigProvider';

export function App() {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'admin-portal-company-settings-order-notification-mfe',
    seed: 'admin-portal-company-settings-order-notification-mfe',
  });

  return (
    <StylesProvider generateClassName={generateClassName}>
      <GlobalStyle />
      <IntlProvider>
        <AppBarConfigProvider />
      </IntlProvider>
    </StylesProvider>
  );
}
