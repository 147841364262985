import { useHasPermission } from 'admin-portal-shared-services';
import { Alert } from 'components/Alert/Alert';
import { useOrderNotification } from 'pages/OrderNotification/provider/orderNotificationContext';
import React, { EffectCallback, useCallback, useEffect, useRef, useState } from 'react';
import { ChipsField } from '../../../../components/ChipsField/ChipsField';
import { Feedbacks, pushNewFeedback } from '../../../../components/Feedbacks/Feedbacks';
import { Modal } from '../../../../components/Modal/Modal';
import * as StyledModal from '../../../../components/Modal/styles';
import { OrderNotificationService } from '../../../../services/orderNotification/OrderNotificationService';
import { OrderNotificationSectionSkeleton } from './OrderNotificationSectionSkeleton';
import * as Styled from './styles';

interface IOrderNotificationProps {
  companyId: string;
  country: string;
}

interface IInitialValuesFormField {
  orderNotification: string;
  bccEmails: string[];
}

enum EnumOrderNotificationOptions {
  NO_SALES_REP = 'BCC List Only (No Sales Rep)',
  PRIMARY_SALES_REP = 'BCC List and Primay Sales Rep',
  ALL_SALES_REPS = 'BCC List and All Sales Reps',
  ALL_SALES_REPS_SUPERVISOR = 'BCC List, All Sales Reps and Team Leader/Manager',
}

export const OrderNotificationSection = ({
  companyId,
  country,
}: IOrderNotificationProps): JSX.Element => {
  const { hasChange, setHasChange } = useOrderNotification();
  const [isLoading, setIsLoading] = useState(true);
  const [bccEmailInputValue, setBccEmailInputValue] = useState('');
  const [orderNotificationOptionSelected, setOrderNotificationOptionSelected] =
    useState<string>('');
  const feedbackRef = useRef();
  const canWriteOrderNotification = useHasPermission('CompanySettings.OrderNotification.Write');
  const [openModalCancel, setOpenModalCancel] = useState<boolean>(false);
  const [initialValuesFormField, setInitialValuesFormField] = useState<IInitialValuesFormField>({
    orderNotification: '',
    bccEmails: [],
  });

  const [bccEmailList, setBccEmailList] = React.useState<string[]>([]);

  const handleChangeBccEmail = (event: React.BaseSyntheticEvent) => {
    setBccEmailInputValue(event.target.value);
  };

  useEffect(() => {
    setHasChange(false);

    let changes = false;
    if (
      initialValuesFormField.orderNotification &&
      orderNotificationOptionSelected !== initialValuesFormField.orderNotification
    )
      changes = true;
    if (!initialValuesFormField.orderNotification && orderNotificationOptionSelected)
      changes = true;

    initialValuesFormField.bccEmails.forEach((email) => {
      if (bccEmailList.indexOf(email) === -1) changes = true;
    });
    bccEmailList.forEach((email) => {
      if (initialValuesFormField.bccEmails.indexOf(email) === -1) changes = true;
    });

    setHasChange(changes);
  }, [orderNotificationOptionSelected, bccEmailList, initialValuesFormField, setHasChange]);

  const fetchOrderNotification: EffectCallback = useCallback(() => {
    const orderNotificationService = new OrderNotificationService();

    setIsLoading(true);
    orderNotificationService
      .getOrderNotificationData(companyId)
      .then((result) => {
        const { data } = result;
        if (data) {
          const {
            bccEmails,
            vendorParticipation: { orderNotificationEmails },
          } = data;

          setInitialValuesFormField({
            orderNotification: orderNotificationEmails,
            bccEmails: bccEmails || [],
          });
          setBccEmailList(bccEmails || []);
          setOrderNotificationOptionSelected(orderNotificationEmails);
        }
      })
      .finally(() => setIsLoading(false));
  }, [companyId]);

  const handleSetOrderNotificationOption = (event: React.BaseSyntheticEvent) => {
    setOrderNotificationOptionSelected(event.target.value);
  };

  useEffect(fetchOrderNotification, [fetchOrderNotification]);

  const updateOrderNotification = async () => {
    const orderNotificationService = new OrderNotificationService();
    const result = await orderNotificationService.updateOrderNotificationData(
      country,
      companyId,
      bccEmailList,
      orderNotificationOptionSelected
    );

    if (result) {
      pushNewFeedback({
        message: 'Order settings successfully updated.',
        variant: 'success',
        elementRef: feedbackRef,
      });
      setInitialValuesFormField({
        bccEmails: bccEmailList,
        orderNotification: orderNotificationOptionSelected,
      });
    } else {
      pushNewFeedback({
        message: "Order settings couldn't be updated now.",
        variant: 'error',
        elementRef: feedbackRef,
      });
    }
  };

  const handleCloseModal = useCallback(() => setOpenModalCancel(false), [setOpenModalCancel]);
  const handleOpenModal = useCallback(() => setOpenModalCancel(true), [setOpenModalCancel]);

  const handleCancelButton = () => {
    setBccEmailList(initialValuesFormField.bccEmails);
    setOrderNotificationOptionSelected(initialValuesFormField.orderNotification);
    handleCloseModal();
  };

  if (isLoading) return <OrderNotificationSectionSkeleton />;

  return (
    <>
      <Styled.FieldContainer>
        <div>
          <Styled.InputLabel htmlFor="select-order-notification-options">
            Order Notification Options
          </Styled.InputLabel>
          <Styled.CustomSelect
            onChange={handleSetOrderNotificationOption}
            value={orderNotificationOptionSelected}
            id="select-order-notification-options"
            disabled={!canWriteOrderNotification}
          >
            {Object.keys(EnumOrderNotificationOptions).map((key) => (
              <option value={key} key={key}>
                {EnumOrderNotificationOptions[key as keyof typeof EnumOrderNotificationOptions]}
              </option>
            ))}
          </Styled.CustomSelect>
          {orderNotificationOptionSelected &&
            orderNotificationOptionSelected !== 'NO_SALES_REP' && (
              <Alert severity="info">
                Email order notifications can only be sent to sales reps if your RAS is providing
                BEES with your sales rep email addresses. Please reach out to BEES Support with any
                questions.
              </Alert>
            )}
        </div>
        <ChipsField
          value={bccEmailInputValue}
          setValue={setBccEmailInputValue}
          onChange={handleChangeBccEmail}
          chips={bccEmailList}
          setChips={setBccEmailList}
          fieldName="BCC E-mails for Order Notification"
          orientations="Use commas for separating values"
          disabled={!canWriteOrderNotification}
        />
        {canWriteOrderNotification && (
          <Styled.Footer>
            <Styled.Button
              variant="outline"
              data-testid="reset fields"
              onClick={handleOpenModal}
              disabled={!hasChange}
            >
              Cancel
            </Styled.Button>
            <Styled.Button
              onClick={updateOrderNotification}
              data-testid="confirm-save"
              disabled={!hasChange}
            >
              Save changes
            </Styled.Button>
          </Styled.Footer>
        )}
        <Feedbacks ref={feedbackRef} />
      </Styled.FieldContainer>

      <Modal
        modalTitle="Are you sure you want to cancel?"
        isModalOpen={openModalCancel}
        handleButtonCloseModal={handleCloseModal}
      >
        <StyledModal.ModalSubtitle>
          All the filled information will be lost and this action can't be undone!
        </StyledModal.ModalSubtitle>
        <StyledModal.ModalFooter>
          <StyledModal.ModalButton
            variant="outline"
            onClick={handleCancelButton}
            data-testid="cancel button"
          >
            Yes, cancel
          </StyledModal.ModalButton>
          <StyledModal.ModalButton onClick={handleCloseModal} data-testid="keep editing button">
            Keep editing
          </StyledModal.ModalButton>
        </StyledModal.ModalFooter>
      </Modal>
    </>
  );
};
