import { Sliders } from '@admin-portal-shared-components/icons';
import { useHasPermission, useSidebar } from 'admin-portal-shared-services';
import React, { useMemo } from 'react';
import { Routes } from '../../Routes';

export const menuIcons = {
  sliders: () => <Sliders />,
};

export const AppBarConfigProvider = (): JSX.Element => {
  const canReadOrderNotification = useHasPermission('CompanySettings.OrderNotification.Read');
  const canWriteOrderNotification = useHasPermission('CompanySettings.OrderNotification.Write');
  const hasCompanySettingsPermission = canReadOrderNotification || canWriteOrderNotification;

  const asideMenuItems = [
    {
      id: '1',
      title: 'Settings',
      icon: menuIcons.sliders,
      path: '/company-settings',
      hasAccess: hasCompanySettingsPermission,
    },
  ];

  const listMenuWithAccess = asideMenuItems.filter((listItem) => listItem.hasAccess);

  const menuItems = useMemo(
    () => ({
      items: listMenuWithAccess,
      utils: [],
    }),
    [listMenuWithAccess]
  );

  useSidebar(menuItems);

  return <Routes />;
};
