import { Tabs } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const TabContainer = styled(Tabs.Root);

export const TabList = styled(Tabs.List);

export const Tab = styled(Tabs.Trigger);

export const TabPanel = styled(Tabs.Content, {
  minHeight: '440px',
  maxHeight: '440px',
  overflow: 'auto',
});
