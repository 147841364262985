import { FormControlLabel, Radio, RadioGroup, SvgIcon } from '@material-ui/core';
import { OrderNotificationContext } from 'pages/OrderNotification/provider/orderNotificationContext';
import React, { useCallback, useEffect } from 'react';
import { Modal } from '../../../components/Modal/Modal';
import * as StyledModal from '../../../components/Modal/styles';
import { IVendor } from '../../../interfaces/Vendors';
import * as Styled from './styles';

interface Props {
  vendors: IVendor[];
  onHandleChange: (vendor: IVendor) => void;
  isLoading: boolean;
}

export function VendorsFilter({ onHandleChange, isLoading, vendors }: Props): JSX.Element {
  const { hasChange, setHasChange } = React.useContext(OrderNotificationContext);
  const [recoverVendorSelected, setRecoverVendorSelected] = React.useState<IVendor>({});
  const [openModalCancel, setOpenModalCancel] = React.useState<boolean>(false);
  const [selectedVendor, setSelectedVendor] = React.useState<Partial<IVendor>>({ vendorId: '' });

  useEffect(() => {
    if (vendors.length > 0) {
      onHandleChange(vendors[0]);
      setSelectedVendor(vendors[0]);
    }
  }, [vendors]);

  const handleCloseModal = useCallback(() => setOpenModalCancel(false), [setOpenModalCancel]);
  const handleOpenModal = useCallback(() => setOpenModalCancel(true), [setOpenModalCancel]);

  const handleCancelButton = () => {
    setSelectedVendor(recoverVendorSelected);
    onHandleChange(recoverVendorSelected);
    handleCloseModal();
    setHasChange(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVendor: IVendor = vendors.find((vendor) => vendor.vendorId === event.target.value);

    if (hasChange) {
      handleOpenModal();
      setRecoverVendorSelected(newVendor);
    } else {
      setSelectedVendor(newVendor);
      onHandleChange(newVendor);
    }
  };

  return (
    <>
      <Styled.StyledFilterSection>
        <Styled.StyledFilterSectionHeader>Company</Styled.StyledFilterSectionHeader>
        <Styled.StyledSectionOptions>
          <RadioGroup
            name="vendors"
            value={selectedVendor.vendorId}
            onChange={handleChange}
            data-testid="select-vendor"
          >
            {vendors.map((vendor: IVendor) => {
              return (
                <FormControlLabel
                  value={vendor.vendorId}
                  control={
                    <Radio
                      style={{ color: 'black', fontWeight: 50 }}
                      disabled={isLoading}
                      checkedIcon={
                        <SvgIcon>
                          <ellipse
                            strokeWidth="1.2px"
                            ry="10"
                            rx="10"
                            cy="11"
                            cx="11"
                            stroke="#000"
                            fill="#fff"
                          />
                          <ellipse
                            strokeWidth="1px"
                            ry="3.125"
                            rx="3.125"
                            cy="11"
                            cx="11"
                            stroke="#000"
                            fill="#000"
                          />
                        </SvgIcon>
                      }
                      icon={
                        <SvgIcon>
                          <ellipse
                            strokeWidth="1.2px"
                            ry="10"
                            rx="10"
                            cy="11"
                            cx="11"
                            stroke="#000"
                            fill="#fff"
                          />
                        </SvgIcon>
                      }
                      data-testid={`radio-${vendor.vendorId}`}
                    />
                  }
                  label={vendor.displayName}
                  key={vendor.vendorId}
                />
              );
            })}
          </RadioGroup>
        </Styled.StyledSectionOptions>
      </Styled.StyledFilterSection>

      <Modal
        modalTitle="Are you sure you want to cancel?"
        isModalOpen={openModalCancel}
        handleButtonCloseModal={handleCloseModal}
      >
        <StyledModal.ModalSubtitle>
          All the filled information will be lost and this action can't be undone!
        </StyledModal.ModalSubtitle>
        <StyledModal.ModalFooter>
          <StyledModal.ModalButton
            variant="outline"
            onClick={handleCancelButton}
            data-testid="cancel button"
          >
            Yes, cancel
          </StyledModal.ModalButton>
          <StyledModal.ModalButton onClick={handleCloseModal} data-testid="keep editing button">
            Keep editing
          </StyledModal.ModalButton>
        </StyledModal.ModalFooter>
      </Modal>
    </>
  );
}
