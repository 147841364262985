import React from 'react';

import { Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

import {
  ButtonsContainer,
  ButtonSkeleton,
  Container,
  Form,
  Heading,
  HeadingContainerSkeleton,
  Image,
  InputGroup,
  LabelContainer,
  LabelSkeleton,
  PreviewSkeleton,
  Shadow,
  StyledInputSkeleton,
  TooltipContainer,
} from './styles';

export const CompanyImageSkeleton = (): JSX.Element => (
  <Container>
    <HeadingContainerSkeleton>
      <Heading>Insert the company image</Heading>
      <TooltipContainer>
        <Tooltip
          title="Your image must have 300x300 pixels and be in the png format"
          placement="right"
          style={{ marginLeft: '10px' }}
        >
          <InfoOutlined />
        </Tooltip>
      </TooltipContainer>
    </HeadingContainerSkeleton>
    <Form role="form">
      <InputGroup>
        <StyledInputSkeleton
          type="file"
          id="fileUpload"
          data-testid="upload-image"
          accept="image/png"
          value=""
        />
        <LabelContainer htmlFor="fileUpload">
          <ButtonSkeleton>Choose file</ButtonSkeleton>
          <LabelSkeleton>No file</LabelSkeleton>
        </LabelContainer>
        <Shadow>
          <PreviewSkeleton>
            <Image />
          </PreviewSkeleton>
        </Shadow>
      </InputGroup>
      <ButtonsContainer>
        <ButtonSkeleton
          type="button"
          variant="secondary"
          size="large"
          css={{
            marginRight: '24px',
          }}
          disabled
        >
          Cancel
        </ButtonSkeleton>
        <ButtonSkeleton type="submit" size="large" disabled>
          Save Changes
        </ButtonSkeleton>
      </ButtonsContainer>
    </Form>
  </Container>
);
