import styled from 'styled-components';

export const StyledScreen = styled.div`
  display: grid;
  grid-template-rows: 72px auto 1fr;
`;

export const StyledHeaderTitle = styled.h1`
  width: 100%;
  color: rgba(0, 0, 0, 0.9);
  font: 600 32px/1.25 'Barlow', sans-serif;
`;

export const StyledHeaderContainer = styled.div`
  margin: 0 auto;
  width: 100%;
`;

export const StyledContainer = styled.div`
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 308px 1fr;
`;

export const StyledHeader = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 16px;
  color: rgba(0, 0, 0, 0.95);
`;
