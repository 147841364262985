import { Alert, AlertProps } from '@material-ui/lab';
import styled from 'styled-components';

export const StyledAlert = styled(Alert) <AlertProps>`
  &.admin-portal-company-settings-order-notification-mfe-MuiAlert-root {
    margin-top: 12px;
    color: rgba(0, 0, 0, 0.9);
    font-family: 'Work Sans', sans-serif;
    border-radius: 8px;
  }
  &.admin-portal-company-settings-order-notification-mfe-MuiAlert-standardSuccess {
    background-color: #d1f7f6;
  }
  &.admin-portal-company-settings-order-notification-mfe-MuiAlert-standardError {
    background-color: #ffdede;
  }
  &.admin-portal-company-settings-order-notification-mfe-MuiAlert-standardInfo {
    background-color: #D1F7F6;
  }
`;
