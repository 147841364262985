import React from 'react';
import * as Styled from './styles';

export const OrderNotificationSectionSkeleton = () => (
  <Styled.FieldContainer data-testid="order-notification-section-loader">
    <Styled.SkeletonSection>
      <Styled.SkeletonSectionElement height={'16px'} width={'20%'} margin={'0 0 8px 0'} />
      <Styled.SkeletonSectionElement height={'40px'} width={'100%'} />
    </Styled.SkeletonSection>
    <Styled.SkeletonSection>
      <Styled.SkeletonSectionElement height={'16px'} width={'20%'} margin={'0 0 8px 0'} />
      <Styled.SkeletonSectionElement height={'16px'} width={'15%'} margin={'0 0 8px 0'} />
      <Styled.SkeletonSectionElement height={'96px'} width={'100%'} />
    </Styled.SkeletonSection>
    <Styled.SkeletonFooter>
      <Styled.SkeletonSectionElement
        height={'40px'}
        width={'120px'}
        borderRadius={'24px'}
        margin={'0 16px 0 0'}
      />
      <Styled.SkeletonSectionElement height={'40px'} width={'120px'} borderRadius={'24px'} />
    </Styled.SkeletonFooter>
  </Styled.FieldContainer>
);
