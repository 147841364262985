import { useAuthenticationService as authService } from 'admin-portal-shared-services';
import axios, { AxiosInstance } from 'axios';
import { v4 } from 'uuid';
import { IVendor } from '../../interfaces/Vendors';
import { getApiHost } from '../host/HostService';
import { getCountries, getToken } from './../../helpers';

export class OrderNotificationService {
  public axiosInstance: AxiosInstance | any;
  private headers: any;

  constructor() {
    this.axiosInstance = axios.create();
    this.headers = {
      Authorization: getToken(),
      requestTraceId: v4(),
    };
  }

  public async getVendors(): Promise<IVendor[]> {
    const URL = `${getApiHost()}/order-notification-mfe/vendors`;
    try {
      const { data } = await authService()
        .enhancedAxios(this.axiosInstance)
        .get(URL, { headers: { ...this.headers, countries: getCountries() } });
      return data;
    } catch (error) {
      return [];
    }
  }

  async getOrderNotificationData(companyId: string): Promise<any> {
    try {
      const { data } = await authService()
        .enhancedAxios(this.axiosInstance)
        .get(`${getApiHost()}/order-notification-mfe/vendors/${companyId}`, {
          headers: { ...this.headers, countries: getCountries() },
        });

      return data;
    } catch {
      return null;
    }
  }

  async updateOrderNotificationData(
    country: string,
    companyId: string,
    bccEmails: string[],
    orderNotificationEmails: string
  ): Promise<any> {
    try {
      const headers = { ...this.headers, country };
      const body = {
        bccEmails,
        vendorParticipation: {
          orderNotificationEmails,
        },
      };

      const { data } = await authService()
        .enhancedAxios(this.axiosInstance)
        .patch(`${getApiHost()}/order-notification-mfe/vendors/${companyId}`, body, { headers });

      return data;
    } catch {
      return null;
    }
  }
}
