import { useHasPermission } from 'admin-portal-shared-services';
import React, { Suspense } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Spinner } from 'supplier-portal-shared-components';
import { OrderNotificationPage } from './pages/OrderNotification/OrderNotification';
import { PrivateRoute } from './PrivateRoute/PrivateRoute';

export const Routes = (): JSX.Element => {
  const canReadOrderNotification = useHasPermission('CompanySettings.OrderNotification.Read');
  const canWriteOrderNotification = useHasPermission('CompanySettings.OrderNotification.Write');
  const hasCompanySettingsPermission = canReadOrderNotification || canWriteOrderNotification;

  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
        <Switch>
          <PrivateRoute
            exact={false}
            path="/company-settings"
            component={OrderNotificationPage}
            permission={hasCompanySettingsPermission}
          />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
