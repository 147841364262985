import styled, { css } from 'styled-components';

interface IButton {
  variant?: string;
}

export const InputLabel = styled.label`
  color: rgba(0, 0, 0, 0.9);
  font: 600 14px/20px 'Work Sans', sans-serif;
`;

export const CustomSelect = styled.select`
  font: 400 14px/20px 'Work Sans', sans-serif;
  width: fill-available;
  border: 1px solid #dfdede;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 12px 8px;
`;

export const FieldContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  justify-content: center;
`;

export const Footer = styled.div`
  display: flex;
  place-content: flex-end;
  cursor: pointer;
  margin-top: 24px;
`;

const setVariantOutline = (variant: string | undefined) =>
  variant === 'outline' &&
  css`
    color: #000;
    border: 1px solid #000000;
    border-radius: 24px;
    background-color: #ffffff;
  `;

export const Button = styled.button<IButton>`
  height: 48px;
  border: 0;
  border-radius: 24px;
  display: flex;
  place-items: center;
  font: 500 18px/24px 'Work Sans', sans-serif;
  color: #fff;
  background-color: #000000;
  padding: 0 24px;
  cursor: pointer;
  text-decoration: none;

  &:first-child {
    margin-right: 16px;
  }

  &[disabled] {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    color: rgba(0, 0, 0, 0.55);
    cursor: not-allowed;
  }

  ${({ variant }) => setVariantOutline(variant)}
`;

export const SkeletonSection = styled.div`
  width: 100%;

  > * {
    background-color: rgb(228, 228, 228);
  }
`;

export const SkeletonFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 24px;

  > * {
    background-color: rgb(228, 228, 228);
  }
`;

interface SkeletonElement {
  height?: string;
  width?: string;
  margin?: string;
  borderRadius?: string;
}

export const SkeletonSectionElement = styled.div<SkeletonElement>`
  ${({ height, width, margin, borderRadius }) => css`
    height: ${height};
    width: ${width};
    margin: ${margin};
    border-radius: ${borderRadius || '12px'};
  `}
`;
