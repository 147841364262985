import { Input } from '@admin-portal-shared-components/input';
import { styled } from '@bees/vision-tokens';
import { Button } from 'components/Button';

export const Container = styled('div', {
  display: 'flex',
  flexFlow: 'column',
  height: '100%',
});

export const HeadingContainer = styled('div', {
  display: 'flex',
  alignItems: 'stretch',
});

export const HeadingContainerSkeleton = styled('div', {
  display: 'flex',
  width: '350px',
  borderRadius: '12px',
  backgroundColor: '$neutral30',
  color: 'transparent',
});

export const Heading = styled('h3', {
  display: 'flex',
});

export const TooltipContainer = styled('div', {
  display: 'flex',
});

export const Form = styled('form', {
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
  justifySelf: 'stretch',
  padding: '20px 0px',
});

export const InputGroup = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
});

export const StyledInput = styled(Input, {
  display: 'none',
});

export const StyledInputSkeleton = styled(Input, {
  display: 'none',
  backgroundColor: '$neutral30',
});

export const LabelContainer = styled('label', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Label = styled('span', {
  marginLeft: '30px',
});

export const LabelSkeleton = styled('span', {
  width: '150px',
  padding: '5px',
  color: 'transparent',
  marginLeft: '30px',
  borderRadius: '20px',
  backgroundColor: '$neutral30',
});

export const Shadow = styled('span', {
  margin: '20px 0px',
  filter: 'drop-shadow(2px 5px 5px rgba(0, 0, 0, 0.5))',
});

export const Preview = styled('figure', {
  position: 'relative',
  height: '150px',
  width: '150px',
  clipPath: 'circle(50% at 50% 50%)',
  shapeOutside: 'circle(50% at 50% 50%)',
  backgroundColor: 'White',
});

export const PreviewSkeleton = styled('figure', {
  position: 'relative',
  height: '150px',
  width: '150px',
  clipPath: 'circle(50% at 50% 50%)',
  shapeOutside: 'circle(50% at 50% 50%)',
  backgroundColor: '$neutral30',
});

export const Image = styled('img', {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%) scale(0.25)',
});

export const ButtonsContainer = styled('div', {
  display: 'flex',
  flex: 1,
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
});

export const ButtonSkeleton = styled(Button, {
  color: 'transparent !important',
  backgroundColor: '$neutral30 !important',
});
