import React, { createContext } from 'react';

export interface IOrderNotificationContext {
  hasChange: boolean;
  setHasChange: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OrderNotificationContext = createContext<IOrderNotificationContext>({
  hasChange: false,
  setHasChange: () => {},
});

export const useOrderNotification = () => React.useContext(OrderNotificationContext);
