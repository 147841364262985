import React, { Dispatch, FC, SetStateAction } from 'react';
import { Chips } from './components/Chips/Chips';
import { InputChip } from './components/InputChip/InputChip';
import * as Styled from './styles';

interface IChipsField {
  value: string;
  onChange: (event: React.BaseSyntheticEvent) => void;
  chips: string[];
  setChips: Dispatch<SetStateAction<string[]>>;
  setValue: Dispatch<SetStateAction<string>>;
  fieldName: string;
  orientations: string;
  disabled?: boolean;
}

export const ChipsField: FC<IChipsField> = ({ onChange, value, setValue, chips, setChips, fieldName, orientations, disabled = false }) => {
  return (
    <Styled.LabelChipsFieldContainer htmlFor="chips">
      <Styled.LabelChipsFieldDescription>
        <p>{fieldName}</p>
        <p>{orientations}</p>
      </Styled.LabelChipsFieldDescription>

      <Styled.InputChipContainer>
        <Chips chips={chips} setChips={setChips} disabled={disabled} />

        <InputChip
          onChange={onChange}
          value={value}
          chips={chips}
          setChips={setChips}
          setValue={setValue}
          data-testid="input-chip"
          disabled={disabled}
        />
      </Styled.InputChipContainer>
    </Styled.LabelChipsFieldContainer>
  );
};
