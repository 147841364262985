import styled from 'styled-components';

export const InputChip = styled.input`
  border: 0;
  font: 400 14px/20px 'Work Sans', sans-serif;

  &:focus {
    outline: none;
  }
  :disabled {
    background: white;
  }
`;