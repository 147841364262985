import styled from 'styled-components';

export const ChipsFieldContainer = styled.div`
  padding: 0 24px;
`;

export const LabelChipsFieldContainer = styled.label``;

export const LabelChipsFieldDescription = styled.div`
  p:first-child {
    color: rgba(0, 0, 0, 0.9);
    font: 600 14px/20px 'Work Sans', sans-serif;
  }

  p {
    color: rgba(0, 0, 0, 0.55);
    font: 400 12px/18px 'Work Sans', sans-serif;
  }
`;

export const InputChipContainer = styled.div`
  font: 400 14px/20px 'Work Sans', sans-serif;
  box-sizing: border-box;
  min-height: 96px;
  width: 100%;
  border: 1px solid #dfdede;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 12px 8px;
  cursor: text;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 8px;
`;


