import { useHasPermission } from 'admin-portal-shared-services';
import { Tabs } from 'components/Tabs';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Filter } from '../../components/Filter/Filter';
import { IVendor } from '../../interfaces/Vendors';
import { CompanyImage } from './components/CompanyImage';
import { OrderNotificationSection } from './components/OrderNotificationSection/OrderNotificationSection';
import { OrderNotificationContext } from './provider/orderNotificationContext';
import { StyledContainer, StyledHeaderContainer, StyledHeaderTitle, StyledScreen } from './styles';

export const OrderNotificationPage = (): JSX.Element => {
  const [vendorSelected, setVendorSelected] = useState<Partial<IVendor>>({
    vendorId: '',
    country: '',
  });

  const [hasChange, setHasChange] = useState(false);

  const history = useHistory();
  const canReadOrderNotification = useHasPermission('CompanySettings.OrderNotification.Read');
  const canWriteOrderNotification = useHasPermission('CompanySettings.OrderNotification.Write');
  const hasPermissionToAccessImageConfiguration = useHasPermission(
    ['CompanySettings.Image.Read', 'CompanySettings.Image.Write'],
    'OR'
  );

  const hasPermissionToAccessOrderNotification =
    canReadOrderNotification || canWriteOrderNotification;

  React.useEffect(() => {
    if (!hasPermissionToAccessOrderNotification) {
      history.push('/error/403');
    }
  }, [history, hasPermissionToAccessOrderNotification]);

  return (
    <OrderNotificationContext.Provider value={{ hasChange, setHasChange }}>
      <StyledScreen className="container-company-settings">
        <StyledHeaderContainer>
          <StyledHeaderTitle>US wholesalers profile</StyledHeaderTitle>
        </StyledHeaderContainer>
        <StyledContainer>
          <Filter vendorChange={setVendorSelected} />
          <Tabs
            tabActive="ORDER_NOTIFICATION"
            tabItems={[
              {
                label: 'Order Notification',
                value: 'ORDER_NOTIFICATION',
                hasPermission: hasPermissionToAccessOrderNotification,
              },
              {
                label: 'Image Configuration',
                value: 'IMAGE_CONFIGURATION',
                hasPermission: hasPermissionToAccessImageConfiguration,
              },
            ]}
            tabPanels={[
              {
                value: 'ORDER_NOTIFICATION',
                content: (
                  <OrderNotificationSection
                    companyId={vendorSelected.vendorId as string}
                    country={vendorSelected.country as string}
                  />
                ),
              },
              {
                value: 'IMAGE_CONFIGURATION',
                content: (
                  <CompanyImage
                    vendorId={vendorSelected.vendorId as string}
                    country={vendorSelected.country as string}
                  />
                ),
              },
            ]}
          />
        </StyledContainer>
      </StyledScreen>
    </OrderNotificationContext.Provider>
  );
};
