import React from 'react';
import { Route, useHistory } from 'react-router-dom';

interface IRoute {
  exact: boolean;
  path: string;
  component: any;
  permission: boolean;
}

export function PrivateRoute({
  exact,
  path,
  component,
  permission,
  ...rest
}: IRoute): JSX.Element | any {
  const historyHook = useHistory();

  React.useEffect(() => {
    if (!permission) {
      historyHook.push('/error/403', false);
    }
  }, [historyHook, permission]);

  return permission && <Route exact={exact} path={path} component={component} {...rest} />;
}
